import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/Spotlight.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/hero-image-01.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/hero-image-02.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/workflow-01.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/workflow-02.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/workflow-03.png");
